<template>
  <div
    class="form-field form-input-checkbox"
    @click="toggleChecked"
    @keypress="toggleChecked"
  >
    <div
      class="form-input-checkbox-element"
      :class="{
        'is-checked': isChecked,
        'has-error': errors.length > 0,
      }"
    />

    <input
      :id="data.field_key"
      ref="checkboxRef"
      v-model="isChecked"
      :name="data.field_key"
      type="checkbox"
      :required="data.is_required"
      class="form-input-checkbox-input"
    />

    <label class="form-input-checkbox-label" :for="data.field_key">
      {{ data.label + (data.is_required ? " *" : "") }}
    </label>

    <FormFieldErrors :errors="errors" />
  </div>
</template>

<script setup>
const emit = defineEmits(['emitValue', 'hasErrors']);

const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    prefillValue: {
        type: [String, Boolean],
        default: false,
    },
});

const isChecked = ref(false);
const checkboxRef = ref(null);

const toggleChecked = () => {
    isChecked.value = !isChecked.value;
};

const { buildValidationObject } = useFormValidation();

const {
    handleEmit, setValue, setFocus, errors,
} = useFormField(
    emit,
    buildValidationObject(props.data),
);

watch(
    () => isChecked.value,
    (newValue) => {
        setValue(isChecked.value);
        setFocus(true);
        handleEmit(newValue, props.data.field_key);

        window.setTimeout(() => {
            setFocus(false);
        }, 100);
    },
);

/*
  data to prefill the field with
*/

onMounted(() => {
    if (props.prefillValue) {
        toggleChecked();
    }
});

watch(() => props.prefillValue, (newValue) => {
    if (newValue === false) {
        isChecked.value = false;
    }
});

</script>
<style lang="scss" scoped>
.form-input-checkbox {
    @include fluid('margin-bottom', 30px, 20px);

    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
}
.form-input-checkbox-input {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    opacity: 0;
}

.form-input-checkbox-label {
    @include typo-font('bold');
    position: relative;
    max-width: calc(100% - 30px);
    padding-top: 2px;
    color: $C_GREEN_DARK;
    font-size: 15px;
}

.form-input-checkbox-element {
    position: relative;
    width: 20px;
    height: 20px;
    border: 2.5px solid $C_PRIMARY;
    margin-right: 10px;
    transition: background 0.15s ease-in-out;

    &.is-checked {
        background: $C_PRIMARY;
    }
}
</style>
